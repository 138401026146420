<template lang="pug">
el-select(
  v-model="data",
  placeholder="请选择",
  @change="handleChange",
  :clearable="clearable"
  :loading="loading"
  :filterable="filterable"
)
  el-option(
    v-for="item in list",
    :label="item.label",
    :value="item.value",
    :key="item.value"
  )
</template>

<script>
export default {
  props: {
    value: { value: undefined },
    list: {
      type: Array,
      value: [],
    },
    clearable: {
      type: Boolean,
      value: true,
    },
    filterable: {
      type: Boolean,
      value: true,
    },
    loading: {
      type: Boolean,
      value: false,
    },
  },
  data() {
    return {
      data: this.value,
    };
  },
  watch: {
    value() {
      // console.log('base change ', this.value);
      this.data = this.value;
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e);
      this.$emit('change', e);
    },
  },
};
</script>
